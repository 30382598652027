@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'react-datetime/css/react-datetime.css';
@import '@bukuwarung/sachet/styles/components/calender.css';
@import '@bukuwarung/sachet/styles/components/chart.css';
@import 'functions.scss';

* {
    box-sizing: border-box;
}

html,
body {
    padding: 0;
    margin: 0;
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
        sans-serif;
}

html {
    height: 100%;
    width: 100%;
}

body,
body > #__next {
    @apply h-full w-full;
}

body {
    @apply text-neutrals-600;
}

button:focus {
    outline: none;
}

a {
    @apply text-blue-80 underline;
}

[role='button'] {
    @apply cursor-pointer;
}

[role='button'] * {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.rdtDays th,
.rdtDays .rdtSwitch {
    vertical-align: middle;
}

.rmdp-container {
    width: 100%;
}

input:disabled,
textarea:disabled,
input:disabled::placeholder,
textarea:disabled::placeholder {
    -webkit-text-fill-color: rgb(163 163 163 / var(--tw-text-opacity));
    opacity: 1;
}

@media (max-width: 600px) {
    [data-id='zsalesiq'] {
        display: none !important;
    }
}
