@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/Roboto-Regular.eot');
  src: url('../public/fonts/Roboto-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../public/fonts/Roboto-Regular.woff2') format('woff2'),
    url('../public/fonts/Roboto-Regular.woff') format('woff'),
    url('../public/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/Roboto-Medium.eot');
  src: url('../public/fonts/Roboto-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('../public/fonts/Roboto-Medium.woff2') format('woff2'),
    url('../public/fonts/Roboto-Medium.woff') format('woff'),
    url('../public/fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/Roboto-Bold.eot');
  src: url('../public/fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/Roboto-Bold.woff2') format('woff2'),
    url('../public/fonts/Roboto-Bold.woff') format('woff'),
    url('../public/fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
